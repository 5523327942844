import React from 'react';

function EditorLabel({ text, children, style }) {
  return (
    <div className={`rounded-t-md px-3 py-2 ${style}`} style={{ fontFamily: 'gellix-medium' }}>
      {text} {children}
    </div>
  );
}

export default EditorLabel;
