import initSqlJs from 'sql.js';

export async function intializesql(dbFilePath = '') {
  try {
    const SQLPromise = initSqlJs({
      locateFile: (file) => `https://sql.js.org/dist/${file}`,
    });

    const dataPromise = fetch(dbFilePath).then((res) => res.arrayBuffer());

    const [SQL, buf] = await Promise.all([SQLPromise, dataPromise]);

    const client = new SQL.Database(new Uint8Array(buf));

    return client;
  } catch (error) {
    console.error(error.message);
    throw error;
  }
}
