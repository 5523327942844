import React, { useEffect, useState } from 'react';
import CustomPreview from './CustomPreview';
import EditorLabel from './EditorLabel';
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';
import Editor from '@monaco-editor/react';
import axios from 'axios';
import { intializesql } from './SQLUtility';
import { TableGenerator, copyClipBoard } from '@utils/helper';
import { Primary, Secondary, SecondaryColor } from 'stories/Button.stories';
import { MdContentCopy } from 'react-icons/md';
import { BsArrowsCollapse, BsArrowsExpand } from 'react-icons/bs';
import Modal from '../Modal';
import { useStore } from '@globalStore/storeProvider';
import { OnboardingFormWithCloseBtn } from '@components/local/courses/forms/onboarding-form';
import { COMPILATION_BASE_URL } from '@lib/api';

async function compileCode(lang, code) {
  try {
    const res = await axios.post(`${COMPILATION_BASE_URL}/api/wb/run-code`, {
      language: lang,
      sourceCode: code,
    });

    return res.data;
  } catch (error) {
    throw error;
  }
}

function MonacoEditor({
  selectedLang,
  code,
  setCode,
  compileStatus,
  setCompileStatus,
  config,
  dbFilePath = '/assets/custom.db',
  singleScreenLayout = false,
  expand = true,
  setExpand,
}) {
  const { isLoading, result } = compileStatus;
  const { theme, fontSize, fullScreen } = config;

  const isDarkTheme = theme === 'vs-dark';

  const editorHeight = fullScreen ? '75vh' : expand ? '370px' : '200px';

  const options = {
    selectOnLineNumbers: false,
    readOnly: false,
    fontSize: fontSize || '14px',
    quickSuggestions: false,
    wordWrap: 'on',
    padding: {
      top: 10,
    },
  };

  function handleEditorValidation(markers) {
    if (!markers) return;

    markers.forEach((marker) => {
      setCompileStatus({
        isLoading: false,
        isError: true,
        result: marker.message,
      });
    });
  }

  const runCodeHandler = async (e) => {
    e && e.preventDefault();

    try {
      setCompileStatus({
        isLoading: true,
        isError: false,
        result: '',
      });

      if (!code) {
        throw new Error('');
      }

      const res = await compileCode(selectedLang, code);

      if (res?.status.description !== 'Accepted') {
        setCompileStatus({
          isLoading: false,
          isError: true,
          result: res.status.description,
        });

        return;
      }

      setCompileStatus({
        isLoading: false,
        isError: false,
        result: res.stdout,
      });
    } catch (error) {
      setCompileStatus({
        isLoading: false,
        isError: true,
        result: !code ? 'Please use correct syntax' : 'Oops! Something went wrong...',
      });
    }
  };

  const runQueryHandler = async () => {
    try {
      setCompileStatus({
        isLoading: true,
        isError: false,
        result: '',
      });

      if (!code) {
        throw new Error('Please write the right query!');
      }

      const DB = await intializesql(dbFilePath);

      const res = DB.exec(code);

      setCompileStatus({
        isLoading: false,
        isError: false,
        result: res?.map((dbRes, index) => (
          <TableGenerator key={index} headings={dbRes?.columns} values={dbRes?.values} />
        )),
      });
    } catch (error) {
      console.log(error.message);
      setCompileStatus({
        isLoading: false,
        isError: true,
        result: error?.message,
      });
    }
  };

  return (
    <div className="px-2">
      {singleScreenLayout ? (
        <SingleScreenCompiler
          code={code}
          editorHeight={editorHeight}
          fontSize={fontSize}
          handleEditorValidation={handleEditorValidation}
          isDarkTheme={isDarkTheme}
          isLoading={isLoading}
          options={options}
          result={result}
          runCodeHandler={runCodeHandler}
          runQueryHandler={runQueryHandler}
          selectedLang={selectedLang}
          setCode={setCode}
          theme={theme}
          expand={expand}
          setExpand={setExpand}
        />
      ) : (
        <div className="grid grid-cols-1 grid-rows-2 gap-x-1 gap-y-4 md:grid-cols-2 md:grid-rows-1">
          <div className="flex flex-col">
            <EditorLabel style={'bg-[#1D2939] text-white flex items-center justify-end'}>
              {selectedLang !== 'sql' ? (
                <button
                  onClick={runCodeHandler}
                  className="flex items-center gap-x-1 rounded bg-red px-3 font-satoshi-medium font-medium  normal-case"
                >
                  Run <IoIosArrowForward />
                </button>
              ) : (
                <button
                  onClick={runQueryHandler}
                  className="flex items-center gap-x-1 rounded bg-red px-3 font-satoshi-medium font-medium  normal-case"
                >
                  Run Query <IoIosArrowForward />
                </button>
              )}
            </EditorLabel>

            <Editor
              height={editorHeight}
              theme={theme}
              defaultValue={code}
              value={code}
              onValidate={handleEditorValidation}
              onChange={setCode}
              options={options}
              language={selectedLang}
              originalLanguage={selectedLang}
              modifiedLanguage={selectedLang}
            />
          </div>

          <div className="flex flex-col">
            <EditorLabel text={'Output'} style={'bg-[#1D2939] text-white'} />
            <CustomPreview
              className={`h-full w-full ${
                isDarkTheme ? 'bg-[#1e1e1e] text-white' : ''
              } text-[${fontSize}] `}
              loading={isLoading}
              result={result}
              previewHeight={editorHeight}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default MonacoEditor;

function SingleScreenCompiler({
  isLoading,
  selectedLang,
  code,
  setCode,
  handleEditorValidation,
  runCodeHandler,
  runQueryHandler,
  result,
  isDarkTheme,
  editorHeight,
  fontSize,
  theme,
  options,
  expand,
  setExpand,
}) {
  const [hideCompiler, setHideCompiler] = useState(false);
  const { isOnboardingFormFilled } = useStore();

  useEffect(() => {
    if (!isLoading) return;

    setHideCompiler(isLoading);
  }, [isLoading]);

  const backToCompiler = () => setHideCompiler(false);

  const isSqlLang = selectedLang === 'sql';

  return (
    <div>
      {!hideCompiler && (
        <div className="flex flex-col">
          <Editor
            height={editorHeight}
            theme={theme}
            defaultValue={code}
            value={code}
            onValidate={handleEditorValidation}
            onChange={setCode}
            options={options}
            language={selectedLang}
            originalLanguage={selectedLang}
            modifiedLanguage={selectedLang}
          />

          <EditorLabel
            style={
              'bg-[#1D2939] text-white flex items-center justify-end gap-x-[8px] rounded-t-none rounded-b-md'
            }
          >
            <SecondaryColor iconCTA size={'small'} onClick={() => setExpand((prev) => !prev)}>
              {expand ? <BsArrowsCollapse size={20} /> : <BsArrowsExpand size={20} />}
            </SecondaryColor>

            <Secondary iconCTA size={'small'} onClick={() => copyClipBoard(code)}>
              <MdContentCopy size={20} />
            </Secondary>

            {isOnboardingFormFilled ? (
              <Primary
                label={isSqlLang ? 'Run Query' : 'Run'}
                onClick={isSqlLang ? runQueryHandler : runCodeHandler}
                customStyle={'flex items-center gap-x-1'}
              >
                <IoIosArrowForward size={20} />
              </Primary>
            ) : (
              <Modal
                open={
                  <Primary
                    label={isSqlLang ? 'Run Query' : 'Run'}
                    customStyle={'flex items-center gap-x-1'}
                  >
                    <IoIosArrowForward size={20} />
                  </Primary>
                }
                boxStyle={'md:w-2/6'}
              >
                <OnboardingFormWithCloseBtn
                  callBack={isSqlLang ? runQueryHandler : runCodeHandler}
                />
              </Modal>
            )}
          </EditorLabel>
        </div>
      )}

      {hideCompiler && (
        <div className="flex flex-col" style={{ height: editorHeight }}>
          <CustomPreview
            className={`h-full w-full ${
              isDarkTheme ? 'bg-[#1e1e1e] text-white' : ''
            } text-[${fontSize}] `}
            loading={isLoading}
            result={result}
            previewHeight={editorHeight}
          />

          <EditorLabel
            text={'Output'}
            style={
              'bg-[#1D2939] text-white flex items-center justify-between flex-row-reverse rounded-t-none rounded-b-md'
            }
          >
            <div className="flex items-center gap-x-[8px]">
              <Primary
                label={'Back'}
                customStyle={'flex items-center gap-x-[8px] flex-row-reverse'}
                onClick={backToCompiler}
                size={'small'}
              >
                <IoIosArrowBack size={20} />
              </Primary>

              <SecondaryColor iconCTA size={'small'} onClick={() => setExpand((prev) => !prev)}>
                {expand ? <BsArrowsCollapse size={20} /> : <BsArrowsExpand size={20} />}
              </SecondaryColor>
            </div>
          </EditorLabel>
        </div>
      )}
    </div>
  );
}
