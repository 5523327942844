import React, { useEffect, useState } from 'react';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import emoji from 'remark-emoji';
import remarkGfm from 'remark-gfm';
import hljs from 'highlight.js';
import 'highlight.js/styles/base16/monokai.css';
import { MdContentCopy } from 'react-icons/md';
import { copyClipBoard } from '@utils/helper';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import rehypeRaw from 'rehype-raw';
import MonacoEditor from '@components/shared/compiler/MonacoEditor';
import { CODING_LANGUAGE } from '@lib/constants';
import useStorage from '@utils/hooks/useStorage';
import { useDispatch, useStore } from '@globalStore/storeProvider';

function MarkDownPreview({ content, dbLocation }) {
  const listStyleType = ['decimal', 'lower-alpha', 'lower-roman'];

  const retrieveInnerChild = (ctx) => {
    const child = ctx?.children?.[0];

    if (typeof child === 'object') {
      return retrieveInnerChild(child?.props);
    }

    return child;
  };

  const listOfCodingLanguage = CODING_LANGUAGE;

  return (
    <>
      <ToastContainer />

      <ReactMarkdown
        rehypePlugins={[emoji, rehypeRaw]}
        remarkPlugins={[remarkGfm]}
        components={{
          h1: (props) => <h1 {...props} className="my-6 font-satoshi-bold text-2xl" />,
          h2: (props) => (
            <h2
              {...props}
              id={JSON.stringify(retrieveInnerChild(props))}
              className="content-heading mb-4 border-l-4 border-red pl-2 font-satoshi-bold text-[24px] leading-8 tracking-wide lg:scroll-mt-20"
            />
          ),
          h3: (props) => <h3 {...props} className="my-4 text-[20px] font-normal leading-7" />,
          p: (props) => <p {...props} className="my-6 text-justify text-[18px] leading-7" />,
          img: (props) => <img className="my-14" src={props.src} alt={props.alt} loading="lazy" />,
          a: ({ node, href, children, ...props }) => {
            return (
              <>
                <a
                  {...props}
                  target="_blank"
                  href={href}
                  rel={href?.includes('.almabetter.com') ? null : 'noreferrer'}
                >
                  <a className="text-base text-blue hover:underline">{children}</a>
                </a>
              </>
            );
          },
          ul: (props) => (
            <ul
              {...props}
              className="ml-4 w-[97%] list-disc text-[1.1rem] font-light leading-7 lg:ml-5"
            />
          ),
          ol: (props) => (
            <ol
              {...props}
              style={{ listStyleType: listStyleType[props?.depth] }}
              className="ml-4 w-[97%] text-[1.1rem] font-light leading-7 lg:ml-5"
            />
          ),
          li: (props) => <li {...props} className="my-6 !list-item pl-1.5 font-light" />,
          strong: (props) => <strong {...props} className="font-satoshi-bold font-bold " />,
          th: (props) => (
            <th
              {...props}
              className="px-4 py-3 text-left font-satoshi-medium text-sm font-semibold uppercase"
            />
          ),
          td: (props) => <td {...props} className="border border-gray-light px-4 py-3 text-left" />,
          thead: (props) => (
            <thead {...props} className="border border-white bg-gray-dark text-white" />
          ),
          tbody: (props) => <tbody {...props} className="text-gray-700" />,
          table: (props) => (
            <div className="w-[105vw] overflow-x-auto lg:w-[54vw]">
              <table
                {...props}
                className="my-2 border border-solid border-gray-light bg-white child:overflow-hidden "
              />
            </div>
          ),
          code: ({ children, inline, className }) => {
            if (!children) return null;
            const str = children[0];
            const language = className?.split('-')[1] || '';

            const isDBLanguage = language === 'ruby';

            if (str.startsWith('<iframe') || str.startsWith('<blockquote')) {
              return (
                <>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: children[0],
                    }}
                  />
                </>
              );
            }

            if (inline) {
              return <code className="text-red">{str}</code>;
            }

            if (listOfCodingLanguage.includes(language) || isDBLanguage) {
              const code = str.replace(/\u00a0/g, ' ');

              return (
                <div className="grid w-full grid-cols-1 grid-rows-1">
                  <MarkDownCompiler
                    input={code}
                    language={isDBLanguage ? 'sql' : language}
                    dbLocation={dbLocation}
                  />
                </div>
              );
            }

            return (
              <pre className="hljs my-4 flex w-[100vw] items-start justify-between overflow-x-auto rounded-md p-3 lg:w-[54vw]">
                <code
                  className={`hljs slim-scroll w-11/12`}
                  dangerouslySetInnerHTML={{
                    __html: hljs.highlightAuto(str).value,
                  }}
                />
                <MdContentCopy
                  className="cursor-pointer"
                  size={18}
                  onClick={() => copyClipBoard(str)}
                />
              </pre>
            );
          },
        }}
      >
        {content}
      </ReactMarkdown>
    </>
  );
}

export default MarkDownPreview;

function MarkDownCompiler({ input, language, dbLocation }) {
  const [compileStatus, setCompileStatus] = useState({
    isLoading: false,
    isError: false,
    result: '',
  });

  const config = {
    fullScreen: false,
    theme: 'vs-dark',
    fontSize: '14px',
  };

  const [code, setCode] = useState(input);

  const [expand, setExpand] = useState(false);

  const { getItemFromLocal } = useStorage();
  const { isOnboardingFormFilled } = useStore();

  const dispatch = useDispatch();

  useEffect(() => {
    const isFilled = getItemFromLocal('onboarding');

    if (isFilled && !isOnboardingFormFilled) {
      dispatch({ type: 'SET_ONBOARDING_FORM', payload: true });
    }
  }, []);

  return (
    <MonacoEditor
      code={code}
      setCode={setCode}
      selectedLang={language}
      compileStatus={compileStatus}
      setCompileStatus={setCompileStatus}
      config={config}
      singleScreenLayout
      expand={expand}
      setExpand={setExpand}
      dbFilePath={dbLocation}
    />
  );
}
