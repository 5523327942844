import { useCallback, useEffect, useRef, useState } from 'react';

import Link from 'next/link';
import { AiFillCheckCircle } from 'react-icons/ai';

import { RCB_OPTION_LIST } from '@lib/constants';
import { HierarchyPrimary, Primary } from 'stories/Button.stories';
import cn from 'classnames';
import { nameRegex } from 'config';
import { emailRegex } from 'config';
import InputField from 'stories/InputField';
import SelectField from 'stories/SelectField';
import OnboardingFormHeader from './OnboardingFormHeader';
import { LeadOnBoarding, SendOTP, VerifyOTP } from '@lib/api';
import { PhoneField } from 'stories/PhoneField';
import { Checkbox } from 'stories/Checkbox';
import { useDispatch } from '@globalStore/storeProvider';
import { loginToAnalyticsSvc, setAttributesInAnalytics } from '@lib/analytics/analytics.service';
import { IoCloseOutline } from 'react-icons/io5';

function OnboardingDetailsForm({ onSubmitClicked, formStyle }) {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    termNCondition: false,
    courseInterest: '',
  });

  const [error, setError] = useState({
    name: null,
    email: null,
    phone: null,
    termNCondition: null,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCheckboxChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.checked });
  };

  const validateForm = useCallback((formData) => {
    const error = {};
    if (!formData.name) {
      error.name = 'Name is required';
    }

    if (formData.name && formData.name.length > 100) {
      error.name = 'Name should be less than 100 characters';
    }

    if (formData.name && !nameRegex.test(formData.name)) {
      error.name = 'Name should contain only alphabets';
    }

    if (!formData.email) {
      error.email = 'Email is required';
    }

    if (formData.email && !emailRegex.test(formData.email)) {
      error.email = 'Invalid email';
    }

    if (!formData.phone) {
      error.phone = 'Phone number is required';
    }

    if (formData.phone && String(formData.phone).length !== 10) {
      error.phone = 'Phone number should be of 10 digits';
    }

    if (!formData.termNCondition) {
      error.termNCondition = 'Please accept the terms and conditions';
    }

    return error;
  }, []);

  const submitForm = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);

    try {
      const error = validateForm(formData);

      setError(error);

      if (Object.keys(error).length > 0) {
        return;
      }

      await onSubmitClicked({
        ...formData,
        phone: `+91${formData.phone}`,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div
      className={cn('flex flex-col gap-y-[24px] rounded-lg bg-white p-[24px]', formStyle)}
      style={{
        boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
      }}
    >
      <OnboardingFormHeader />
      <form onSubmit={submitForm} className="flex flex-col gap-y-[24px] !font-satoshi-regular">
        <InputField
          inputStyle="!rounded-lg !border !border-[#A3A3A3] !py-[8px] !px-[12px] !text-[16px] !leading-6 placeholder:text-gray-400 text-black"
          type="text"
          name="name"
          value={formData.name}
          placeholder="Enter Full Name"
          onChange={handleChange}
          error={error.name}
        />

        <InputField
          inputStyle="!rounded-lg !border !border-[#A3A3A3] !py-[8px] !px-[12px] !text-[16px] !leading-6 placeholder:text-gray-400 text-black"
          type="email"
          name="email"
          value={formData.email}
          placeholder="Enter Your Email"
          onChange={handleChange}
          error={error.email}
        />

        <SelectField
          name="courseInterest"
          selectStyle="bg-white !rounded-lg !border !border-[#A3A3A3] !py-[8px] !px-[12px] !text-[16px] !leading-6 invalid:text-gray-400 text-black"
          value={formData.courseInterest}
          onChange={handleChange}
          required
        >
          <option value="" selected disabled hidden>
            Course Interest
          </option>
          {Object.entries(RCB_OPTION_LIST).map(([key, value]) => {
            return (
              <option key={key} value={key}>
                {value}
              </option>
            );
          })}
        </SelectField>

        <PhoneField
          name="phone"
          placeholder="Mobile Number"
          className="!rounded-lg !border !border-[#A3A3A3] !py-[8px] !px-[12px] !text-[16px] !leading-6 text-black"
          countryCodeStyle={'text-black'}
          phoneInputContainerStyle="placeholder:text-gray-400"
          value={formData.phone}
          onChange={handleChange}
          error={error.phone}
        />

        <Checkbox
          name="termNCondition"
          onChange={handleCheckboxChange}
          checked={formData.termNCondition}
          error={error.termNCondition}
        >
          By continuing, you confirm that you have read and agreed to AlmaBetter's{' '}
          <Link href="/pages/terms-of-use">
            <a>
              <span className="cursor-pointer text-red">Terms</span>
            </a>
          </Link>{' '}
          and{' '}
          <Link href="/pages/privacy-policy">
            <a>
              <span className="cursor-pointer text-red">Privacy policy</span>
            </a>
          </Link>
        </Checkbox>
        <Primary
          type="submit"
          disabled={isSubmitting}
          label="Submit"
          size="large"
          customStyle="w-full"
        />
      </form>
    </div>
  );
}

function ResendOtpForm({ referenceId, phone, resetForm, requestOtp }) {
  const [resendOtpForm, setResendOtpForm] = useState({
    phone: phone.replace('+91', ''),
  });

  const [resendOtpFormError, setResendOtpFormError] = useState({
    phone: null,
  });

  const [timeLeftForResend, setTimeLeftForResend] = useState(0);
  const [isResendOtpFormSubmitting, setIsResendOtpFormSubmitting] = useState(false);
  const [apiErrorMsg, setApiErrorMsg] = useState(null);
  const timerRef = useRef(null);

  useEffect(() => {
    startTimer();
    return () => {
      clearInterval(timerRef.current);
    };
  }, []);

  const validateResendOtpForm = useCallback((formData) => {
    const errors = {};

    if (!formData.phone) {
      errors.phone = 'Phone number is required';
    }

    if (formData.phone && String(formData.phone).length !== 10) {
      errors.phone = 'Phone number should be of 10 digits';
    }

    return errors;
  }, []);

  const startTimer = () => {
    let timeLeft = 30;

    timerRef.current = setInterval(() => {
      if (timeLeft > 0) {
        setTimeLeftForResend(timeLeft);

        timeLeft--;
      } else {
        setTimeLeftForResend(0);

        clearInterval(timerRef.current);
      }
    }, 1000);
  };

  const handleChangeResendOtpForm = (e) => {
    setResendOtpForm({ ...resendOtpForm, [e.target.name]: e.target.value });
  };

  const submitResendOtpForm = async (e) => {
    e.preventDefault();

    setIsResendOtpFormSubmitting(true);

    setApiErrorMsg(null);

    try {
      const errors = validateResendOtpForm(resendOtpForm);

      setResendOtpFormError(errors);

      if (Object.keys(errors).length > 0) {
        return;
      }

      await requestOtp({ phone: `+91${resendOtpForm.phone}` });

      startTimer();
    } catch (error) {
      console.error(error);

      let message = 'Failed sending OTP';

      if (error.isAxiosError && error.response?.status === 500 && error.response.data?.message) {
        message = error.response.data.message;
        if (message.includes('Timed Out')) {
          resetForm();
        }
      }

      setApiErrorMsg(message);
    } finally {
      setIsResendOtpFormSubmitting(false);
    }
  };

  return (
    <form onSubmit={submitResendOtpForm}>
      <div className="flex flex-col gap-y-[6px] text-black">
        <PhoneField
          label={'Phone Number'}
          labelStyle={'text-[14px] leading-5 font-satoshi-medium'}
          className="!rounded-lg !border !border-[#A3A3A3] !py-[8px] !px-[12px] !text-[16px] !leading-6"
          value={resendOtpForm.phone}
          name="phone"
          placeholder="Enter Mobile Number"
          onChange={handleChangeResendOtpForm}
          error={resendOtpFormError.phone}
        />

        <HierarchyPrimary
          label={timeLeftForResend > 0 ? `${timeLeftForResend} seconds` : 'Resend OTP'}
          type="submit"
          size="small"
          primary={false}
          customStyle="text-[#646464] disabled:text-[#646464] self-end text-[12px] font-satoshi-regular leading-[18px]"
          disabled={isResendOtpFormSubmitting || timeLeftForResend > 0}
        />
      </div>
      {!!apiErrorMsg && (
        <p className="ml-1 mt-1 text-center text-xs text-red md:text-sm">{apiErrorMsg}</p>
      )}
    </form>
  );
}

function OtpForm({ onSubmitClicked, referenceId, phone, resetForm, requestOtp }) {
  const [otpForm, setOtpForm] = useState({
    otp: '',
  });

  const [otpFormError, setOtpFormError] = useState({
    otp: null,
  });

  const [isVerifyOtpFormSubmitting, setIsVerifyOtpFormSubmitting] = useState(false);

  const [apiErrorMsg, setApiErrorMsg] = useState(null);

  const handleChangeOTPForm = (e) => {
    setOtpForm({ ...otpForm, [e.target.name]: e.target.value });
  };

  const validateSubmitForm = useCallback((formData) => {
    const errors = {};

    if (!formData.otp) {
      errors.otp = 'OTP is required';
    }

    if (formData.otp && formData.otp.length !== 6) {
      errors.otp = 'OTP should be of 6 digits';
    }

    return errors;
  }, []);

  const resetFormInSomeTime = () => {
    setTimeout(() => {
      resetForm();
    }, 2000);
  };

  const submitForm = async (e) => {
    setIsVerifyOtpFormSubmitting(true);

    setApiErrorMsg(null);

    try {
      e.preventDefault();

      const errors = validateSubmitForm(otpForm);

      setOtpFormError(errors);

      if (Object.keys(errors).length > 0) {
        return;
      }

      await onSubmitClicked(otpForm);
    } catch (error) {
      console.error(error);
      let message = 'Failed verifying OTP';
      if (error.isAxiosError && error.response?.status === 500 && error.response.data?.message) {
        message = error.response.data.message;
        if (message.includes('OTP expired')) {
          resetFormInSomeTime();
        }
      }
      setApiErrorMsg(message);
    } finally {
      setIsVerifyOtpFormSubmitting(false);
    }
  };

  return (
    <div
      className="flex flex-col gap-y-[24px] rounded-lg bg-white p-[24px]"
      style={{
        boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
      }}
    >
      <OnboardingFormHeader />
      <div className="flex flex-col gap-y-[16px]">
        <ResendOtpForm
          phone={phone}
          referenceId={referenceId}
          resetForm={resetFormInSomeTime}
          requestOtp={requestOtp}
        />

        <form onSubmit={submitForm} className="flex flex-col gap-y-[24px] text-black">
          <InputField
            type="text"
            value={otpForm.otp}
            name="otp"
            error={otpFormError.otp}
            onChange={handleChangeOTPForm}
            placeholder="Enter Your OTP"
            inputStyle="!rounded-lg !border !border-[#A3A3A3] !py-[8px] !px-[12px] !text-[16px] !leading-6 placeholder:text-gray-400"
          />
          <Primary
            type="submit"
            disabled={isVerifyOtpFormSubmitting}
            label="Verify"
            size="large"
            customStyle="w-full"
          />
          {!!apiErrorMsg && (
            <p className="ml-1 mt-1 text-center text-xs text-red md:text-sm">{apiErrorMsg}</p>
          )}
        </form>
      </div>
    </div>
  );
}

function SuccessMessage({ onSuccessCb }) {
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => dispatch({ type: 'SET_ONBOARDING_FORM', payload: true }), 2000);

    localStorage.setItem('onboarding', true);

    return () => onSuccessCb && onSuccessCb();
  }, []);

  return (
    <div
      className="rounded-lg bg-white p-[24px]"
      style={{
        boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
      }}
    >
      <div className="flex flex-col items-center gap-y-[16px]">
        <AiFillCheckCircle className="text-[#079455]" size={48} />

        <div className="flex flex-col gap-y-[4px]">
          <p className="text-center font-satoshi-bold text-[20px] leading-[30px] text-black">
            Thank you for Interest
          </p>

          <p className="text-center font-satoshi-medium text-[12px] leading-[18px] text-gray-400">
            Thank you for sharing your details with us. Happy coding!
          </p>
        </div>

        {/* <Primary
          linkCTA
          href={`${process.env.NEXT_PUBLIC_GROW_DOMAIN}/data-science/classroom`}
          label="Preview your Class"
        /> */}
      </div>
    </div>
  );
}

export default function OnboardingForm({ onBoardingFormStyle, onSuccessCb }) {
  const [requestRegistrationResponseWithPhone, setRequestRegistrationResponseWithPhone] =
    useState();

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const onBoardingSubmitApi = async (data) => {
    const { name, phone, email, courseInterest } = data;

    const fullName = name.split(' ');

    let payload = {
      firstName: fullName.shift(),
      lastName: fullName.join(' ') || '',
      email,
      phone,
      courseInterest,
      source: 'run_code',
    };

    try {
      const leadData = await LeadOnBoarding(payload);

      const userId = leadData?.onboardingInfo?.userId;

      if (userId) {
        loginToAnalyticsSvc(userId);

        window?.gtag?.('event', 'conversion', {
          send_to: 'AW-595701718/Zn11CL3fgvkCENbfhpwC',
          transaction_id: userId,
        });

        window?.fbq?.('track', 'CompleteRegistration', { eventID: userId });
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const requestOtp = async (data) => {
    try {
      setAttributesInAnalytics(data.name, data.email, data.phone);

      const response = await SendOTP({ phone: data.phone });

      if (response.message === 'OTP Sent') {
        onBoardingSubmitApi(data);
        setRequestRegistrationResponseWithPhone({ ...response, ...data });
      }
    } catch (e) {
      console.error(e);
    }
  };

  const verifyOtp = async (data) => {
    const { otp } = data;

    const response = await VerifyOTP({
      refId: requestRegistrationResponseWithPhone.refId,
      otp,
    });

    if (response.message == 'verification success') {
      setShowSuccessMessage(true);
    }
  };

  const resetForm = () => {
    setRequestRegistrationResponseWithPhone(null);
  };

  return (
    <>
      {!requestRegistrationResponseWithPhone && (
        <OnboardingDetailsForm onSubmitClicked={requestOtp} formStyle={onBoardingFormStyle} />
      )}

      {!!(requestRegistrationResponseWithPhone && !showSuccessMessage) && (
        <OtpForm
          referenceId={requestRegistrationResponseWithPhone.refId}
          phone={requestRegistrationResponseWithPhone.phone}
          onSubmitClicked={verifyOtp}
          resetForm={resetForm}
          requestOtp={requestOtp}
        />
      )}

      {showSuccessMessage && <SuccessMessage onSuccessCb={onSuccessCb} />}
    </>
  );
}

export function OnboardingFormWithCloseBtn({ formStyle, callBack, closeModal }) {
  return (
    <div className="relative w-full">
      <div onClick={closeModal} className="absolute right-2 top-2 cursor-pointer">
        <IoCloseOutline size={24} className="text-gray-navigation" />
      </div>
      <OnboardingForm onBoardingFormStyle={formStyle} onSuccessCb={callBack} />
    </div>
  );
}
