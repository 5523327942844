import React from 'react';
import { BsArrowRepeat } from 'react-icons/bs';

function CustomPreview({ className, loading = false, result = '', previewHeight }) {
  if (loading)
    return (
      <div
        className={`bg-white ${className} flex items-center justify-center`}
        style={{ maxHeight: previewHeight }}
      >
        <BsArrowRepeat className="animate-spin text-2xl text-red" />
      </div>
    );

  return (
    <div
      className={`slim-scroll !overflow-scroll whitespace-pre-wrap bg-white p-3 ${className}`}
      style={{ maxHeight: previewHeight }}
    >
      {result}
    </div>
  );
}

export default CustomPreview;
