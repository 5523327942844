import React, { useState } from 'react';
import cn from 'classnames';

function addPropsToReactElement(element, props) {
  if (React.isValidElement(element)) {
    return React.cloneElement(element, props);
  }
  return element;
}

function addPropsToChildren(children, props) {
  if (!Array.isArray(children)) {
    return addPropsToReactElement(children, props);
  }
  // If props needed in Multiple childrens react component as well
  // return children.map((childElement) =>
  //   addPropsToReactElement(childElement, props)
  // );
}

const Modal = ({ open, component, children, boxStyle }) => {
  const [isOpen, setOpen] = useState(false);

  const closeModal = () => {
    setOpen(false);
  };

  const openModal = () => {
    setOpen(true);
  };

  return (
    <>
      {addPropsToChildren(open, { onClick: openModal })}
      {isOpen && (
        <div
          className="fixed inset-0 z-[1111] bg-backdrop bg-opacity-40 filter backdrop-blur-0 backdrop-filter transition-opacity"
          role="dialog"
        >
          <div className="fixed inset-0 z-[111] overflow-y-auto">
            <div
              onClick={() => setOpen(false)}
              className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0"
            >
              <div
                onClick={(e) => e.stopPropagation()}
                className={cn(
                  'transform rounded-lg bg-white text-left shadow-xl transition-all',
                  boxStyle
                )}
                style={{ textWrap: 'wrap' }}
              >
                {addPropsToChildren(component || children, { closeModal })}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;

//* USAGE
// setOpen hooks available in  children or component passed as well
//* 1
// <MyAccordion
//   trigger={<DeleteIcon className='text-red-300 cursor-pointer' />}
//   component={<ConfirmDialog accept={handleDelete} />}
// />;
//! OR
//* 2
//  <MyAccordion
//    trigger={<DeleteIcon className='text-red-300 cursor-pointer' />}
//  >
//    <ConfirmDialog accept={handleDelete} />
//  </MyAccordion>;
